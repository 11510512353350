import React, { useState, useRef, useEffect } from "react";
import "../Invoice/Invoice.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link, useLocation } from "react-router-dom";
import { FaCut } from "react-icons/fa";
import mainlogo from "../../assets/img/tiq8-Logo-white.png";
import QRCode from "react-qr-code";
import moment from "moment";
import img1 from "../../assets/img/event-imgs/big-1.jpg";
import { FcHome } from "react-icons/fc";
import { useReactToPrint } from "react-to-print";
import { GlobalConstants } from "../../utils/GlobalConst";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function Invoice() {
  const componentRef = useRef();
  const [text_seatname, setText_seatname] = useState("Seat Name");
  const [text_backtohome, setText_backtohome] = useState("Back to home");
  const [text_download, setText_download] = useState("Download");
  const [text_invoice, setText_invoice] = useState("Invoice");
  const [text_invoiceto, setText_invoiceto] = useState("Invoice to");
  const [text_invoiceid, setText_invoiceid] = useState("Invoice ID");
  const [text_total, setText_total] = useState("Total");
   const [text_eveDetails, setText_eveDetails] = useState("Event Details");
   const [text_unitPrice, setText_unitPrice] = useState("Unit Price");
   const [text_qty, setText_qty] = useState("Qty");

   const [text_type, setText_type] = useState("Type");
   const [text_invoiceTotal,setText_invoiceTotal] = useState("Invoice Total");
   const [text_powerBy, setText_powerBy] = useState("Powered by TiQ8");
  const resellEveDetail = JSON.parse(sessionStorage.getItem("resellEveDetail"));
  const ownerDetails = JSON.parse(sessionStorage.getItem("ownerDetails"));
  console.log(ownerDetails);
  const resellTicketDetail = JSON.parse(
    sessionStorage.getItem("resellTicketDetail")
  );
  const data = useLocation();
  const eventID = data.state !== null ? data.state.event_id : 0;
  console.log(eventID);
  const [back, setBack] = useState("#FFFFFF");
  const [fore, setFore] = useState("#000000");
  const [size, setSize] = useState(128);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_seatname(
        xpath.select1("/resources/string[@name='text_seatname']", doc)
          .firstChild.data
      );
      setText_invoice(
        xpath.select1("/resources/string[@name='text_invoice']", doc).firstChild
          .data
      );
      setText_invoiceto(
        xpath.select1("/resources/string[@name='text_invoiceto']", doc)
          .firstChild.data
      );
      setText_invoiceid(
        xpath.select1("/resources/string[@name='text_invoiceid']", doc)
          .firstChild.data
      );
      setText_total(
        xpath.select1("/resources/string[@name='text_total']", doc).firstChild
          .data
      );
      setText_backtohome(
        xpath.select1("/resources/string[@name='text_backtohome']", doc)
          .firstChild.data
      );
      setText_download(
        xpath.select1("/resources/string[@name='text_download']", doc)
          .firstChild.data
      );
      setText_eveDetails(
        xpath.select1("/resources/string[@name='text_eveDetails']", doc)
          .firstChild.data
      );
      setText_unitPrice(
        xpath.select1("/resources/string[@name='text_unitPrice']", doc)
          .firstChild.data
      );
      setText_qty(
        xpath.select1("/resources/string[@name='text_qty']", doc).firstChild
          .data
      );
      setText_type(
        xpath.select1("/resources/string[@name='text_type']", doc).firstChild
          .data
      );
      setText_invoiceTotal(
        xpath.select1("/resources/string[@name='text_invoiceTotal']", doc)
          .firstChild.data
      );
      setText_powerBy(
        xpath.select1("/resources/string[@name='text_powerBy']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Header />
      <div className="invoice clearfix p-80 mt-5">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="d-flex justify-content-center gap-2">
              <center>
                <Link to="/" className="main-btn btn-hover h_40 mb-3 ">
                  <FcHome className="homeicon" /> {text_backtohome}
                </Link>
              </center>
              <center>
                <Link
                  className="main-btn btn-hover h_40 mb-3"
                  onClick={handlePrint}
                >
                  {text_download}
                </Link>
              </center>
            </div>
            <div className="col-lg-8 col-md-10" ref={componentRef}>
              {/* <center>
                <Link to="/" className="main-btn btn-hover h_40 mb-3">
                  <FcHome /> Back to home
                </Link>
              </center> */}
              <div className="invoice-header justify-content-between">
                <div className="invoice-header-logo">
                  <img src={mainlogo} alt="invoice-logo" />
                </div>
                {/* <div className="invoice-header-text">
                  <Link to="#" className="download-link">
                    Download
                  </Link>
                </div> */}
              </div>
              <div className="invoice-body">
                <div className="invoice_dts">
                  <div className="row">
                    <div className="col-md-12">
                      <h2 className="invoice_title">{text_invoice}</h2>
                    </div>
                    <div className="col-md-6">
                      <div className="vhls140">
                        <ul>
                          <li>
                            <div className="vdt-list">
                              {text_invoiceto}{" "}
                              {sessionStorage.getItem("userfirstname")}{" "}
                              {sessionStorage.getItem("userlastname")}
                            </div>
                          </li>
                          {/* <li>
                            <div className="vdt-list">140 St Kilda Rd</div>
                          </li>
                          <li>
                            <div className="vdt-list">Melbourne, Victoria</div>
                          </li>
                          <li>
                            <div className="vdt-list">3000, Australia</div>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="vhls140">
                        <ul>
                          <li>
                            <div className="vdt-list">
                              {text_invoiceid} :{" "}
                              {resellTicketDetail[0]?.paymentid}
                            </div>
                          </li>
                          {/* <li>
                            <div className="vdt-list">
                              Order Date :{" "}
                              {moment(resellTicketDetail[0]?.updatedAt).format(
                                "DD MMM,yyyy"
                              )}
                            </div>
                          </li> */}
                          {/* <li>
                            <div className="vdt-list">Near MBD Mall,</div>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-table bt_40">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">{text_eveDetails}</th>
                          <th scope="col">{text_type}</th>
                          <th scope="col">{text_qty}</th>
                          <th scope="col">{text_unitPrice}</th>
                          <th scope="col">{text_total}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            <Link to="#" target="" className="invoice-link-sty showpassnight">
                              {resellEveDetail?.EventTitle}
                            </Link>
                          </td>
                          <td>{resellEveDetail?.EventType}</td>
                          <td>1</td>
                          <td>
                            {" "}
                            {resellEveDetail?.sell_currency}{" "}
                            {Number(resellTicketDetail[0].seat_resell_price)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </td>
                          <td>
                            {" "}
                            {resellEveDetail?.sell_currency}{" "}
                            {Number(resellTicketDetail[0].seat_resell_price)
                              .toFixed(2)
                              .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                          </td>
                        </tr>

                        <tr>
                          <td colspan="1"></td>
                          <td colspan="5">
                            <div className="user_dt_trans text-end pe-xl-4">
                              <div className="totalinv2">
                                {text_invoiceTotal} : {/* USD */}
                                {resellEveDetail?.sell_currency}{" "}
                                {Number(resellTicketDetail[0].seat_resell_price)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </div>
                              {/* <p>Paid via Paypal</p> */}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="invoice_footer">
                  <div className="cut-line">
                    <i className="fa-solid fa-scissors">
                      <FaCut />
                    </i>
                  </div>
                  <div className="main-card">
                    <div className="row g-0">
                      <div className="col-lg-7">
                        <div className="event-order-dt p-4">
                          <div className="event-thumbnail-img">
                            <img
                              src={
                                resellEveDetail?.EventImagePath === "path"
                                  ? img1
                                  : resellEveDetail?.EventImagePath
                              }
                              alt=""
                            />
                          </div>
                          <div className="event-order-dt-content">
                            <h5>{resellEveDetail?.EventTitle}</h5>
                            {/* <span>Wed, Jun 01, 2022 5:30 AM. Duration 1h</span> */}
                            <span>
                              {" "}
                              {moment(resellEveDetail?.StartDate).format(
                                "DD MMM,yyyy"
                              ) ===
                              moment(resellEveDetail?.EndDate).format(
                                "DD MMM,yyyy"
                              ) ? (
                                <>
                                  {" "}
                                  {moment(resellEveDetail?.StartDate).format(
                                    "ddd, DD MMM, yyyy"
                                  )}
                                  {moment(resellEveDetail?.StartTime).format(
                                    "hh:mm a"
                                  )}
                                  &nbsp;.
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {moment(resellEveDetail?.StartDate).format(
                                    "ddd, DD MMM, yyyy"
                                  )}
                                  ,{" "}
                                  {moment(resellEveDetail?.StartTime).format(
                                    "hh:mm a"
                                  )}{" "}
                                  -{" "}
                                  {moment(resellEveDetail?.EndDate).format(
                                    "ddd, DD MMM, yyyy"
                                  )}
                                  ,{" "}
                                  {moment(resellEveDetail?.StartTime).format(
                                    "hh:mm a"
                                  )}{" "}
                                  &nbsp;. Duration{" "}
                                  {new Date(
                                    resellEveDetail.EndTime
                                  ).getHours() -
                                    new Date(
                                      resellEveDetail.StartTime
                                    ).getHours()}{" "}
                                  h
                                </>
                              )}
                            </span>
                            <div className="buyer-name">
                              {" "}
                              {ownerDetails[0]?.ownername}
                            </div>
                            {/* <div className="booking-total-tickets">
                              <i className="fa-solid fa-ticket rotate-icon"></i>
                              <span className="booking-count-tickets mx-2">
                                1
                              </span>
                              x Ticket
                            </div> */}
                            <div className="booking-total-grand">
                              {text_total} :{" "}
                              <span>
                                {resellEveDetail?.sell_currency}{" "}
                                {Number(resellTicketDetail[0].seat_resell_price)
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="QR-dt p-4">
                          <ul className="QR-counter-type">
                            <li></li>
                            <li>{text_seatname}</li>
                            <li>{resellTicketDetail[0]?.seat_name}</li>
                          </ul>
                          <div className="QR-scanner">
                            <QRCode
                              value={
                                "Event Name:--" +
                                resellEveDetail?.EventTitle +
                                "Seat:--" +
                                resellTicketDetail[0]?.seat_name +
                                "Start Date:--" +
                                moment(resellEveDetail?.StartDate).format(
                                  "ddd, DD MMM, yyyy"
                                ) +
                                "Start Time:--" +
                                moment(resellEveDetail?.StartTime).format(
                                  "hh:mm a"
                                )
                              }
                              bgColor={back}
                              fgColor={fore}
                              size={size === "" ? 0 : size}
                            />
                            {/* <img src="images/qr.png" alt="QR-Ticket-Scanner"/> */}
                          </div>
                          <p>{text_powerBy}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="cut-line">
                    <i className="fa-solid fa-scissors">
                      <FaCut />
                    </i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Invoice;
